<template>
  <portal to="footer">
    <div class="container mx-auto flex justify-center items-center">
      <h2 class="font-sans text-4xl uppercase tracking-widest">
        Hello
      </h2>
    </div>
  </portal>
</template>

<script>

  export default {
    setup () {

    },

    components: {

    }
  }
</script>
